import AOS from 'aos';
import videojs from 'video.js';
import './Youtube.min.js'

var segments = [];
var newProducts = [];
var allProducts = [];
var segmentProducts = [];
let segmentID;

$(document).on('turbolinks:load', function() {

    AOS.init({
        delay: 50
    });
    setPadding();
    loginForm();
    modalInit();
    modalUtilities();

    // Brand video on page
    if($('#brand-video').length > 0 ){
        videojs('brand-video', {
            liveui : true,
            fluid: true,
            muted: false,
            autoplay: false
        });
    }

    //Banner video
    if($('#banner-video').length > 0 ){
        videojs('banner-video');
    }

    // Banner slider
    $('.slider').slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        fade: true,
        dots: true,
        appendDots: '.slider--dots'
    })

    // Product Carousel on brand page
    $('.productCarousel').slick({
        arrows: true,
        dots: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 5
                }
            },
            {
            breakpoint: 1160,
            settings: {
                slidesToShow: 4
            }
        }]
    });
    // If carousel is products, not segments
    $('.carouselProductCard').on('click', function(){
        productClickEvent($(this), "all");
    } )

    // If carousel is products, not segments
    $('.newProductsGridCard').on('click', function(){
        productClickEvent($(this), "all");
    } )

    // Brand story tabs
    tabSliderInit();
    var tabEl = $('a[data-bs-toggle="tab"]');
    tabEl.on('shown.bs.tab', function(event){
        $('.tab-pane').find('.brandStory--content__photos.slick-slider').slick('unslick')
        $('.tab-pane').find('.brandStory--content__photosNav.slick-slider').slick('unslick')
        var oldVideo = $(event.relatedTarget).attr('aria-controls')
        var oldVideoID = '#' + oldVideo
        var getOldVideo = $(oldVideoID).find('.vjs-player').attr('id')
        if ( getOldVideo != undefined ) {
            videojs(getOldVideo).dispose();
        }

        tabSliderInit();
    })


    // Testimonial Slider
    $('.testimonial__slider').slick({
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 4000,
        fade: true

    })

});

// Destroy stuff on page leave
$(document).on('turbolinks:before-cache', function() {

    if ($('.productCarousel').length > 0) {
        $('.productCarousel').slick("unslick");
    }

    if ($('.slider').length > 0) {
        $('.slider').slick("unslick");
    }

    if ($('#brand-video').length > 0) {
        videojs('brand-video').dispose();
    }
    if ($('#banner-video').length > 0) {
        videojs('banner-video').dispose();
    }
    segments = [];
    newProducts = [];
    allProducts = [];
    segmentProducts = [];
});


// Slider in brand story tabs
function tabSliderInit(){
    $('.tab-pane.active').find('.brandStory--content__photos').slick({
        slidesToShow: 1,
        dots: false,
        arrows: true,
        infinite: false,
        asNavFor: '.tab-pane.active .brandStory--content__photosNav'
    });
    $('.tab-pane.active').find('.brandStory--content__photosNav').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: false,
        dots: true,
        arrows: false,
        infinite: false,
        asNavFor: '.tab-pane.active .brandStory--content__photos',
        focusOnSelect: true
    });

    var brandStoryVideo = $('.tab-pane.active').find('.brandStory--content__videoWrapper');
    if ( brandStoryVideo.length > 0 ) {
        brandStoryVideoInit(brandStoryVideo);
    }

}

// Video init in brand story tabs
var highlightVideo,
    highlightYoutube,
    highlightVideoPoster;

function brandStoryVideoInit(e){
    highlightVideo = $(e).attr('data-highlight-video-file')
    highlightYoutube = $(e).attr('data-highlight-video-youtube')
    highlightVideoPoster = $(e).attr('data-highlight-video-poster')
    if ( highlightYoutube ){
        $(e).html(
            "<video controls=\"true\" width=\"100%\" id=\"highlight_video\" class=\"video-js vjs-default-skin vjs-theme-forest vjs-player\" " +
            "data-setup='{ \"techOrder\": [\"youtube\"], \"sources\": [{ \"type\": \"video/youtube\", \"src\": \"" + highlightYoutube + "\"}] }' " +
            "></video>"
            );
        videojs('highlight_video');
        console.log('Video init')

    } else {
        $(e).html(
            '<video controls preload id="highlight_video" class="vjs-theme-forest vjs-player" width="100%" poster="' + highlightVideoPoster + '" >' +
            '<source src="' + highlightVideo + '" type="video/mp4">' +
            '</video>'
            )
    }
}

// Login form
function loginForm(){
    let validForm = false

    if ( $('#user_email').length > 0 && $('#user_email').val().length > 1 ) {
        $('.step-one .button.next').addClass('step hidden');
        $('.step-two').removeClass('hidden');
        $('.step-three').removeClass('hidden');
    }

    $('#user_email').on('change keyup', function(){
        if ( $('#user_email').val() != "" ) {
            $('.step-one .button.next').removeClass('disabled');
        } else {
            $('.step-one .button.next').addClass('disabled');
        }
    })
    $('#new_user').on('keypress', function(e){
        let keyPressed = e.keyCode || e.which;
        if ( keyPressed === 13 && validForm == false ){
            e.preventDefault();
            $('.step-one .button.next').trigger('click')
        }
    })
    $('.step-one .button.next').on('click', function(e){
        e.preventDefault();
        $.get('/checkemail?email='+$("#user_email").val(), function(data){
            if ( data == true ){
                $('.step-one .button.next').addClass('step hidden');
                $('.step-two').removeClass('hidden');
                $('.step-three').removeClass('hidden');
                validForm = true
            } else {
                $('.formResponse').removeClass('hidden')   
            }
        });
    })
}


// Nav/Footer height/paddign
function setPadding() {
    let footerHeight = $('footer').outerHeight();
    $('.mainContainer').css('padding-bottom', footerHeight );

    let navHeight = $('nav.index').outerHeight();
    navHeight = navHeight + 10;
    $('.banner').css('padding-top', navHeight );
}


// HANDLING MODAL AND PRODUCTS
function modalInit(){
    $(".modalProductCard.new").each(function () {
        newProducts.push($(this).attr("data-product-id"));
    })
    
    $(".allProductsCard").each(function () {
        allProducts.push($(this).attr("data-product-id"));
    })

    $("[data-productcard-type='segment']").each(function (index) {
        segments.push($(this).attr("data-segment-id"));
    })
}

// Modal Navigation for 
var currentProductPosition,
    prevProductPosition,
    nextProductPosition,
    prevProdID,
    prevProdName,
    nextProdID,
    nextProdName;

function modalNavigation(navigationSet, activeID, container){
    currentProductPosition = navigationSet.indexOf(activeID)
    
    prevProductPosition = parseInt(currentProductPosition) - 1
    nextProductPosition = parseInt(currentProductPosition) + 1


    if ( prevProductPosition >= 0 ){
        prevProdID = navigationSet[prevProductPosition]
        prevProdName = $('[data-product-id="' + prevProdID + '"]').attr('data-product-name')
        $('.product-modal-prev').html(prevProdName);
        $('.product-modal-prev').removeClass('hidden')
    } else {
        $('.product-modal-prev').addClass('hidden') 
    }
    if ( nextProductPosition < navigationSet.length ){
        nextProdID = navigationSet[nextProductPosition]
        nextProdName = $('[data-product-id="' + nextProdID + '"]').attr('data-product-name')
        $('.product-modal-next').html(nextProdName);
        $('.product-modal-next').removeClass('hidden') 
    } else {
        $('.product-modal-next').addClass('hidden') 
    }


    $('.product-modal-prev').on('click', function(){
        $.get('/product?id='+prevProdID, function(data){
            productModal(data, prevProdID, container);
        } );
    })

    $('.product-modal-next').on('click', function(){
        $.get('/product?id='+nextProdID, function(data){
            productModal(data, nextProdID, container);
        } );
    })
}

// Segment Navigation
var currentSegmentPosition,
    prevSegmentPosition,
    nextSegmentPosition,
    prevSegID,
    prevSegName,
    nextSegID,
    nextSegName;

function modalSegmentNavigation(navigationSet, activeID){
    currentSegmentPosition = navigationSet.indexOf(activeID)
    
    prevSegmentPosition = parseInt(currentSegmentPosition) - 1
    nextSegmentPosition = parseInt(currentSegmentPosition) + 1

    if ( prevSegmentPosition > 0 ){
        prevSegID = navigationSet[prevSegmentPosition]
        prevSegName = $('[data-segment-id="' + prevSegID + '"]').attr('data-segment-name')
        $('.segment-modal-prev').html(prevSegName);
        $('.segment-modal-prev').removeClass('hidden')
    } else {
        $('.segment-modal-prev').addClass('hidden') 
    }
    if ( nextSegmentPosition < navigationSet.length ){
        nextSegID = navigationSet[nextSegmentPosition];
        nextSegName = $('[data-segment-id="' + nextSegID + '"]').attr('data-segment-name');
        $('.segment-modal-next').html(nextSegName);
        $('.segment-modal-next').removeClass('hidden') 
    } else {
        $('.segment-modal-next').addClass('hidden') 
    }


    $('.segment-modal-prev').on('click', function(){
        $.get('/segment?id='+prevSegID, function(data){
            segmentModal(data, prevSegID);
        } );
    })

    $('.segment-modal-next').on('click', function(){
        $.get('/segment?id='+nextSegID, function(data){
            segmentModal(data, nextSegID);
        } );
    })
}

let productsContainer;

function modalUtilities(){

    $('#productModal').on('show.bs.modal', function(event) {
        // WHAT WAS CLICKED
        let button = event.relatedTarget

        // SET THE MODAL STATE
        let modalType = $(button).attr('data-modal-type')
        $(this).find('.modal-dialog').attr('data-modal-state', modalType )

        // DISPLAY CONTENT BASED ON TYPE
        $(this).find('.modal-content').hide()

        if ( modalType == "productIndex" ) {
            if( $(button).attr('data-segment-name') == "NEW"){
                productsContainer = "new";
                $('.products-index').find('.productCard:not(.new)').hide()
                $('.products-index').find('.modal-title').html("New Products")
                $('[data-products-container="productIndex"]').attr('data-show-new-products', "true")
            } else {
                productsContainer = "all";
                $('.products-index').find('.productCard:not(.new)').show()
                $('.products-index').find('.modal-title').html("All Products")
                $('[data-products-container="productIndex"]').attr('data-show-new-products', "false")
            }
            // SHOW THE INDEX CONTENT
            $(this).find('.modal-content.products-index').show()
            
        } else if ( modalType == "segment" ) {
            productsContainer = "segment";
            let segmentName = $(button).attr('data-segment-name')
            segmentID = $(button).attr('data-segment-id')
            $('.segment-show').find('.modal-title').html(segmentName);
            
            $.get('/segment?id='+segmentID, function(data){
                segmentModal(data, segmentID);
                $('[data-products-container="segment"] .modalProductCard').on('click', function(){
                    productClickEvent($(this), "segment");
                } )
            });
            // SHOW THE SEGMENT CONTENT
            $(this).find('.modal-content.segment-show').show()
        } else if ( modalType == "productDetail" ) {
            productsContainer = "all";
            // SHOW THE PRODUCT DETAIL CONTENT
            $(this).find('.modal-content.product-show').show()
        }

        $('.modal-dialog').find('.productCard').on('click', function(){
            productClickEvent($(this), productsContainer);
        })

    })

    $('#productModal').on('hide.bs.modal', function(event) {
        closedModal();
    })
}

function closedModal(){

    if ( videojs.getPlayers.product_video ){
        videojs('product_video').dispose();
    }
    $('.modal-dialog').find('.modal-content').hide()
    $('#product-modal-content').html("");
    $('#segment-modal-content').html("");
    
    $(this).find('.modal-dialog').attr('data-modal-state', "" )
}

let currentSegment,
    slideIndex;

function segmentModal(data, segment) {
    // THE CURRENT SEGMENT
    currentSegment = segment
    slideIndex = $('.productCard[data-segment-id=' + currentSegment + ']').attr('data-segment-index')

    // THE CURRENT SEGMENT'S PRODUCTS
    segmentProducts = []
    $("[data-productcard-type='segmentProduct']").each(function (index) {
        segmentProducts.push($(this).attr("data-product-id"));
    })

    modalSegmentNavigation(segments, currentSegment);

    $('[data-products-container="segment"] .modalProductCard').on('click', function(){
        productClickEvent($(this), "segment");
    } )
}

// Product Click 
function productClickEvent(e, container){
    $('.modal-dialog').find('.modal-content').hide()
    $('.modal-dialog').find('.product-show').show()
    
    let prodID = $(e).attr('data-product-id')
    $.get('/product?id='+prodID, function(data){
        productModal(data, prodID, container);
    });
}

let getProductsContainer,
    currentProduct,
    productFiles,
    selectedFiles,
    photoContainer,
    targetPhoto,
    productVideo,
    productYoutube;

function productModal(data, product, container){
    currentProduct = product
    productsContainer = container

    $('.btn-back').on('click', function(){
        let backLocation = $(this).parents('.modal-dialog').attr('data-modal-state');
        if ( backLocation == 'segment' ){
              $(this).parents('.modal-dialog').find('.modal-content').hide()
              $(this).parents('.modal-dialog').find('.segment-show').show()
        } else if ( backLocation == 'productIndex' ){
            let indexState = $('[data-products-container="productIndex"]').attr('data-show-new-products')
            if ( indexState == 'true' ){
                $(this).parents('.modal-dialog').find('.modal-content').hide()
                $('.products-index').find('.productCard:not(.new)').hide()
                $(this).parents('.modal-dialog').find('.products-index').show() 
            } else {
                $(this).parents('.modal-dialog').find('.modal-content').hide()
                $('.products-index').find('.productCard').show()
                $(this).parents('.modal-dialog').find('.products-index').show()
            }
              
        }
        $('#product-modal-content').html("");
    })
  
    getProductsContainer = $('.modal-dialog').attr('data-modal-state')

    if (getProductsContainer == 'productDetail') {
            $('.modal-product-detail').find('.btn-back').hide()
    } else {
            $('.modal-product-detail').find('.btn-back').show()
    }
    
    if ( productsContainer == "segment" ){
        
        modalNavigation( segmentProducts, currentProduct, productsContainer);

    } else if ( productsContainer == "new" ){
        
        modalNavigation(newProducts, currentProduct, productsContainer);

    } else {
        modalNavigation(allProducts, currentProduct, productsContainer);

    }
  
    productFiles = $('.modal--productFiles__nav').find('.button').first().attr('data-product-files')
    $('[data-product-files="' + productFiles + '"]').addClass('active')

    $('div[data-product-files]').on('click', function(){
            $('.modal--productFiles__nav').find('.button.active').removeClass('active')
            $('.modal--productFiles__contentItem.active').removeClass('active')

            selectedFiles = $(this).attr('data-product-files')
            $('[data-product-files="' + selectedFiles + '"]').addClass('active')
    })

    $('.modal--productImagery__navItem').on('click', function(){
        photoContainer = $(this).parents('.productImagery__wrapper')
        targetPhoto = $(this).attr('data-product-image')
        $('.modal--productImagery__navItem.active').removeClass('active')
        $(this).addClass('active')
        if ( targetPhoto == 'video' ) {
            $(photoContainer).find('.modal--productImagery__image').html('')
            productVideo = $(this).attr('data-product-video-file')
            productYoutube = $(this).attr('data-product-video-youtube')
            $(photoContainer).find('.modal--productImagery__image').hide()
            if ( productYoutube ){
                
                $(photoContainer).find('.modal--productImagery__video').html(
                    "<video controls=\"true\" width=\"100%\" id=\"product_video\" class=\"video-js vjs-default-skin vjs-theme-forest vjs-player\" " +
                    "data-setup='{ \"techOrder\": [\"youtube\"], \"sources\": [{ \"type\": \"video/youtube\", \"src\": \"" + productYoutube + "\"}] }' " +
                    "></video>"
                    );
                    videojs('product_video');

            } else {
                $(photoContainer).find('.modal--productImagery__video').html(
                    '<video controls preload id="product_video" class="vjs-theme-forest vjs-player" >' +
                    '<source src="' + productVideo + '" type="video/mp4">' +
                    '</video>'
                    )
            }
        } else {
                $(photoContainer).find('.modal--productImagery__image').html('<img src="' + targetPhoto + '" >')
                $(photoContainer).find('.modal--productImagery__image').show()
                videojs('product_video').dispose();
                $(photoContainer).find('.modal--productImagery__video').html('')
        }
    })
}
